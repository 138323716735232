<template>
  <div class="home">

    <div class="page-container">

      <div class="info-container">

        <div class="info-content">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>

        <div class="info-content fake">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>

        <i class="fad fa-chess-queen-alt"></i>
      </div>

      <div class="team-list">

        <div class="team-container" v-for="(team, i) in teams" :key="team.id">

          <div class="team-logo" :class="{
            'second': i === 1,
            'third': i === 2,
          }">
            <span class="possition">{{ i + 1 }}</span>
            <span class="sufix" v-if="i === 0">er</span>
            <span class="sufix" v-if="i === 1">nd</span>
            <span class="sufix" v-if="i === 2">th</span>
            <i class="fad fa-chess-queen-alt"></i>
          </div>

          <span class="team-name">
            {{ team.name }}
          </span>

          <canvas id="graph" :ref="`graph-${i}`"></canvas>

          <!-- {{ team.members }} -->

        </div>


      </div>

      <div class=""></div>

    </div>

  </div>
</template>

<script>
import { db } from '../firebase'
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import Chart from 'chart.js'

moment.locale('fr');

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default {
  name: 'Classement',

  firestore() {
    return {
      users: db.collection('users').where('userId', '==', this.user.id),
      teams: db.collection('teams-new')
    }
  },

  data () {
    return {
      users: [],
      teams: [],

      moment
    }
  },

  beforeMount () {

    setTimeout(_ => {
var config = {
			type: 'line',
			data: {
        borderWidth: 8,
				labels: Array(12).fill(null).map((x, i) => `${i + 1}`),
				datasets: [{
          borderWidth: 8,
					label: 'My First dataset',
					backgroundColor: 'red',
					borderColor: 'red',
					data: Array(12).fill(null).map(_ => randomIntFromInterval(0, 10)),
          fill: false
				}]
			},
			options: {
				responsive: true,
        legend: {
          display: false,
        },
				plugins: {
					title: {
						display: false,
					},
					tooltip: {
						display: false,
					},
          
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
				scales: {
					xAxes: [{ display: false}],
					yAxes: [{ display: false}]
				}
			}
		}

      const ctx = this.$refs['graph-0']

      var myLineChart = new Chart(ctx, config)
    }, 1200)

    
  },

  computed: {
    ...mapState(['user']),

    userData() {
      return this.users.find(x => x.userId === this.user.id) || {}
    },

    currentTeam () {
      return this.teams.find(x => x.id === this.userData.currentTeam) || {}
    }
  }

}

</script>

<style lang="scss" scoped>

.team-container {
  height: 96px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  background: #171717;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .team-name {
    color: white;
    text-transform: uppercase;
    margin-left: 18px;
  }

  #graph {
    height: 64px !important;
    width: 300px !important;
  }
}
.team-logo {
  position: relative;
  padding: 2px;
  box-shadow: antiquewhite;
  height: 64px;
  width: 64px;
  background: #F2994A;
  background: linear-gradient(to right, #F2C94C, #F2994A);
  border-radius: 10%;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  svg {
    position: absolute;
    right: -31px;
    height: 64px;
    width: 64px;
    color: #ca7914;
  }

  .possition {
    color: white;
    text-shadow: 1px 1px 6px #00000059;
    font-size: 28px;
    font-weight: 700;
  }

  .sufix {
    font-size: 14px;

    color: white;
    font-weight: 400;
    margin-left: 2px;
    margin-top: 7px;
  }

  &.second {
    background: #757F9A !important;
    background: linear-gradient(to right, #D7DDE8, #757F9A)  !important;

    svg {
      color: #bdbdbd;
    }
  }

  &.third {
    background: #B79891;
    background: linear-gradient(to right, #94716B, #B79891);

    svg {
      color: #bdbdbd;
    }
  }

}


.page-container {
  margin-top: 32px;
}

.info-container {
  position: relative;

  padding: 18px 24px;
  border-radius: 10px;
  color: white;
  margin-bottom: 42px;
  font-size: 15px;
  line-height: 18px;

  overflow: hidden;

  background-color: #FBAB7E;
  background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);

  box-shadow: #00000033 0px 2px 8px 0px;
  
  &::after {
    color: #fbad7d;
    position: absolute;
    top: -2px;
    font-weight: 700;
    letter-spacing: 2px;
    left: 24px;
    text-transform: uppercase;
    background: #1e1e1e;
    border: 2px solid #fab07a;
    padding: 4px 8px;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    content: 'Team Rouge';
  }

  svg {
    position: absolute;
    top: 0;
    left: 49.5%;
    height: 100%;
    width: 100%;
    color: #ffa93ff2;
    z-index: 0;
  }

  .info-content {
    margin-top: 22px;

    z-index: 1;
    opacity: 0;

    line-height: 20px;

    text-shadow: 1px 1px 6px #00000059;

    &.fake {
      opacity: 1;
      position: absolute;
      top: 18px;
      margin-right: 24px;
    }
  }
}




@media screen and (max-width: 1500px) {

}

@media screen and (max-width: 1225px) {

}

@media screen and (max-width: 900px) {

}


@media screen and (max-width: 610px) {
}

</style>

